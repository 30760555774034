import axios from 'axios'

const API_URL = process.env.VUE_APP_API_HOST_DOMAIN
const headers = {
    'Content-Type': 'application/json; charset=utf-8'
}
const formDataHeaders = {
    'Content-Type': 'multipart/form-data'
}
export default {
    post(urlPath, data) {
        return this.request(urlPath, 'POST', headers, data)
    },
    put(urlPath, data) {
        return this.request(urlPath, 'PUT', headers, data)
    },
    get(urlPath) {
        return this.request(urlPath, 'GET', headers, null)
    },
    delete(urlPath) {
        return this.request(urlPath, 'DELETE', headers, null)
    },
    getFile(urlPath, fileName) {
        window.open(API_URL + urlPath, '_blank')
    },
    uploadFile(urlPath, formData) {
        return this.request(urlPath, 'POST', formDataHeaders, formData)
    },
    request(urlPath, method, headers, data) {
        data = data || {}
        const apiToken = localStorage.getItem('big-cms-api-token')
        if (apiToken) {
            headers = {
                ...headers
            }
        }

        return new Promise((resolve, reject) => {
            axios({
                url: API_URL + '/' + urlPath,
                data: data,
                method: method,
                headers: headers
            }).then(response => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    }
}
